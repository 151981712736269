<template>
  <step-wrapper
    title="Статистические показатели"
    class="consolidated-report-step4"
  >
    <v-form :readonly="readonly" v-model="valid">
      <div
        class="consolidated-report-step4__items mt-5"
        v-if="dataSource.StatisticIndicatorsContent && !loading"
      >
        <div
          class="consolidated-report-step4__item"
          v-for="item in dataSource.StatisticIndicatorsContent.Indicators"
          :key="item.Id"
        >
          <div style="align-self: center" v-if="false">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-checkbox v-model="item.IncludeReport" @click.stop />
                </div>
              </template>

              {{
                item.IncludeReport ? "Включен в отчет" : "Не включен в отчет"
              }}
            </v-tooltip>
          </div>
          <div class="consolidated-report-step4__item-chart">
            <statistic-card
              :statisticIndicator="item"
              :showYear="null"
              hideInfo
            >
              <template slot="action">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-checkbox
                        v-model="item.IncludeReport"
                        @click.stop
                        hide-details
                        style="margin: 0"
                      />
                    </div>
                  </template>

                  {{
                    item.IncludeReport
                      ? "Включен в отчет"
                      : "Не включен в отчет"
                  }}
                </v-tooltip>
              </template></statistic-card
            >
          </div>
        </div>
      </div>
      <div class="mt-12">
        <div class="title">Заключение</div>
        <v-textarea
          v-if="dataSource.StatisticIndicatorsContent"
          :value="dataSource.StatisticIndicatorsContent.Conclusion"
          @change="dataSource.StatisticIndicatorsContent.Conclusion = $event"
          placeholder="Заключение..."
        ></v-textarea>
      </div>
    </v-form>
  </step-wrapper>
</template>
<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import statisticCard from "../../../../statisticIndicator/statisticCard";

import { defaultSummaryReport } from "@/data/defaultObjects";
import SummaryReportService from "@/services/SummaryReportService";
export default {
  name: "consolidated-report-step4",
  components: { stepWrapper, statisticCard },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step4,
      getDefaultObject: defaultSummaryReport.step4(),
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },

      loading: false,
      dataSource: {},
    };
  },
  computed: {},
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediate: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
    DataBeforeSave(dataSource) {
      console.log(dataSource);
      dataSource.StatisticIndicatorsContent.Indicators =
        dataSource.StatisticIndicatorsContent.Indicators.map((e) => ({
          Id: e.Id,
          IncludeReport: e.IncludeReport,
          IndicatorEvaluation: e.IndicatorEvaluation,
        }));
      return dataSource;
    },
    async del(item) {
      if (
        !(await this.$confirm(
          `Вы действительно хотите удалить показатель ${item.Name} ?`
        ))
      )
        return;
      this.dataSource.StatisticIndicatorsContent.Indicators =
        this.dataSource.StatisticIndicatorsContent.Indicators.filter(
          (e) => e.Id !== item.Id
        );
    },
    actual() {
      this.init({
        query: {
          actual: true,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.consolidated-report-step4 {
  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 0 0 50%;
    max-width: 50%;

    &-chart {
      padding: 12px 12px;
    }

    &-indicator {
      align-self: center;
      padding-left: 45px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      .consolidated-report-step4__item-chart {
        padding-top: 0;
      }
    }
  }
}
</style>
